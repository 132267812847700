import React, { useState } from "react"
import { Link, graphql, navigate, useStaticQuery } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import postPath from "../utils/post-path"
import Slider from "./slider"

const SWIPE_THRESHOLD = 10

const CarouselCartonStyled = styled.div`
  display: inline-block;
  margin: 0 2%;
  position: relative;

  .cartonSwitch {
    width: 264px;
    height: 278px;
    position: relative;
    top: 0;
    display: block;

    .gatsby-image-wrapper,
    img {
      width: inherit !important;
      height: inherit !important;
    }
  }
  .front {
    top: 0;
    width: inherit;
    height: inherit;
    position: absolute;
    transition: opacity 1s;
    opacity: ${p => (p.over ? "0" : "1")};
  }
  .back {
    top: 0;
    width: inherit;
    height: inherit;
    position: absolute;
    transition: opacity 1s;
    opacity: ${p => (p.over ? "1" : "0")};
  }

  @media (max-width: 768px) {
    margin: 30px 0;

    .cartonSwitch {
      width: 198px;
      height: 209px;

      .gatsby-image-wrapper,
      img {
        width: 198px !important;
        height: 209px !important;
      }
    }
  }
`

const CarouselCarton = ({ data }) => {
  const [mouseMove, setMouseMove] = useState({ x: 0, y: 0 })
  const [over, setOver] = useState(false)
  const flavor = data.acf.flavor
  const img = flavor.carton_image ? (
    <div className="front">
      <Img
        loading="lazy"
        fixed={flavor.carton_image.localFile.childImageSharp.fixed}
      />
    </div>
  ) : (
    ""
  )
  const inside = flavor.inside_image ? (
    <div className="back">
      <Img
        loading="lazy"
        fixed={flavor.inside_image.localFile.childImageSharp.fixed}
      />
    </div>
  ) : (
    ""
  )
  const url = postPath("/flavors/", data.acf.flavor.name)
  return (
    <CarouselCartonStyled
      over={over}
      onMouseEnter={e => setOver(true)}
      onMouseLeave={e => setOver(false)}
    >
      <Link
        onMouseDown={e => {
          setMouseMove({ x: e.clientX, y: e.clientY })
        }}
        onMouseUp={e => {
          // Making the carousel only select if no swiping occured.
          const x = e.clientX
          const y = e.clientY
          const old = mouseMove
          const swiping =
            Math.abs(x - old.x) > SWIPE_THRESHOLD ||
            Math.abs(y - old.y) > SWIPE_THRESHOLD
          if (!swiping) {
            navigate(url)
          }
        }}
        onClick={e => e.preventDefault()}
        className="cartonSwitch"
        to={url}
      >
        {img}
        {inside}
      </Link>
    </CarouselCartonStyled>
  )
}

const CarouselsStyled = styled.div`
  padding: 0;
  position: relative;
  text-align: center;
  width: calc(100vw + 264px);
  margin: 0 -132px;

  .inner {
    padding: 50px 0 30px 0;
    @media (max-width: 768px) {
      padding: 10px 0 10px 0;
    }
  }

  .cta {
    position: absolute;
    top: 10px;
    height: 140px;
    width: 100%;
    padding: 0 10vw;
    text-align: left;
  }
`

const Carousels = () => {
  const data = useStaticQuery(graphql`
    query {
      flavors: allWordpressWpFlavors(sort: { fields: [date], order: DESC }) {
        edges {
          node {
            title
            acf {
              flavor {
                name
                not_for_sale
                carton_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 264, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
                inside_image {
                  localFile {
                    childImageSharp {
                      fixed(width: 264, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const flavors = data.flavors.edges.map(e => e.node)

  return (
    <CarouselsStyled>
      <div className="inner">
        <Slider
          settings={{
            responsive: [
              {
                breakpoint: 4000,
                settings: {
                  slidesToShow: 7,
                  slidesToScroll: 5,
                },
              },
              {
                breakpoint: 1668,
                settings: {
                  slidesToShow: 6,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 1468,
                settings: {
                  slidesToShow: 5,
                  slidesToScroll: 4,
                },
              },
              {
                breakpoint: 1268,
                settings: {
                  swipeToSlide: true,
                  mobileFirst: true,
                  slidesToShow: 4,
                  speed: 300,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 768,
                settings: {
                  swipeToSlide: true,
                  autoplay: false,
                  mobileFirst: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  speed: 300,
                },
              },
              {
                breakpoint: 480,
                settings: {
                  swipeToSlide: true,
                  autoplay: false,
                  mobileFirst: true,
                  slidesToShow: 3,
                  slidesToScroll: 1,
                  speed: 300,
                },
              },
            ],
          }}
        >
          {flavors
            .filter(f => !f.acf.flavor.not_for_sale)
            .map((flavor, i) => (
              <CarouselCarton data={flavor} key={i} />
            ))}
        </Slider>
      </div>
    </CarouselsStyled>
  )
}

export default Carousels
