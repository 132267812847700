import React from "react"
import Slick from "react-slick"
import "./slick.css"

const defaultSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  touchThreshold: 150,
  speed: 2000,
  autoplaySpeed: 4000,
}
const Slider = ({ settings, children }) => {
  const s = { ...defaultSettings, ...settings }

  return <Slick {...s}>{children}</Slick>
}

export default Slider
